@import 'src/styles/variables/variables';

@include useTheme();

.FullBleedSliderHero {
  position: relative;

  @include min-width(tablet) {
    max-height: 573px;
  }

  @include min-width(desktop) {
    max-height: 841px;
  }

  .swiper {
    width: 100%;
    height: 100%;

    @include min-width(tablet) {
      max-height: 573px;
    }

    @include min-width(desktop) {
      max-height: 841px;
    }

    .slide {
      position: relative;
      min-height: 250px;
      display: block;

      @include min-width(small-tablet) {
        margin-left: 20px;
        width: calc(100% - 40px);
        max-width: 1250px;
      }

      @include min-width(large-tablet) {
        margin-left: 26px;
        width: calc(100% - 56px);
      }

      .slideImage {
        background: $core2;
        max-width: 100%;
      }

      .slideOverlay {
        position: absolute;
        bottom: 0;
        width: 100%;
        flex-shrink: 0;
        background: linear-gradient(180deg, rgba(54, 43, 36, 0.30) 0%, rgba(54, 43, 36, 0.00) 100%);
        rotate: 180deg;
        height: 65px;

        @include min-width(desktop) {
          height: 357px;
        }
      }

      .slideAuthor {
        @include secondaryFont(400);
        position: absolute;
        z-index: 10;
        color: $core2;
        font-size: 12px;
        line-height: 30px;
        bottom: 12px;
        right: 11px;

        @include min-width(small-tablet) {
          right: 33px;
          bottom: 20px;
        }

        @include min-width(desktop) {
          font-size: 20px;
          bottom: 33px;
          right: 136px;
        }
      }
    }
  }

  .MaxWidthContainer {
    width: 100%;
    max-width: 1440px;

    @include min-width(small-tablet) {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
      bottom: 0;
    }
  }

  .ContentContainer {
    background: $tint1;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1;
    padding: 71px 15px 48.95px 15px;

    @include min-width(small-tablet) {
      min-width: 250px;
      position: absolute;
      bottom: 50px;
      left: 70px;
      padding: 75px 35px 31px 23.05px;
      align-items: flex-start;
    }

    @include min-width(tablet) {
      min-width: 375px;
      position: absolute;
      padding: 95px 35px 41px 23.05px;
      align-items: flex-start;
      bottom: 115px;
    }

    @include min-width(medium-desktop) {
      position: absolute;
      bottom: 87px;
      left: 120px;
      padding: 148px 36px 61px 36px;
      align-items: flex-start;
    }

    @include min-width(large-desktop) {
      min-width: 551px;
      padding: 148px 36px 58px 43px;
      left: 142px;
      bottom: 87.5px;
    }

    .topBadge {
      position: absolute;
      top: -10px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      align-content: center;
      flex-flow: row;
      min-width: 200px;

      span {
        text-align: center;
        font-size: 9px;
        font-weight: 500;
        line-height: 20.433px;
        letter-spacing: 0.56px;
        text-transform: uppercase;
        margin: 0 0 0 5px;
        padding-top: 15px;
      }

      @include min-width(tablet) {
        width: 62.45px;
        height: 77.22px;
        background: url('/static/label.svg') no-repeat;
        background-size: 62.45px 77.22px;
        top: -15px;
        min-width: inherit;
        flex-flow: column;
        z-index: 5;
        left: 39.62px;
        transform: inherit;

        svg {
          width: 26.188px;
          height: 28.994px;
          margin: 6.71px 0 7.94px 16.79px;
        }

        span {
          text-align: center;
          width: 54.41px;
          font-size: 5.75px;
          font-weight: 500;
          line-height: 8.729px;
          letter-spacing: 0.336px;
          margin: 0 0 0 1.36px;
          padding: 0;
        }
      }

      @include min-width(large-tablet) {
        width: 101px;
        height: 115px;
        top: -19px;
        left: 23.05px;
        background-size: 101px 115px;

        svg {
          width: 39px;
          height: 43.179px;
          margin: 10px 0 11.82px 25px;
        }

        span {
          text-align: center;
          width: 84px;
          font-size: 9px;
          font-weight: 500;
          line-height: 13px;
          letter-spacing: 0.5px;
          margin: 0 0 0 5px;
        }
      }

      @include min-width(medium-desktop) {
        left: 36px;
      }

      @include min-width(large-desktop) {
        left: 43px;
      }
    }

    .Eyebrow {
      margin-bottom: 17px;
      @include max-width(desktop) {
        display: none;
      }
    }

    .Title {
      @include secondaryFont(400);
      font-size: 28px;
      line-height: 32px;
      letter-spacing: -0.4px;
      color: $core1;
      text-align: center;
      margin-bottom: 23.78px;

      @include min-width(small-tablet) {
        text-align: left;
        max-width: 316px;
        font-size: 38px;
        line-height: 42px;
        letter-spacing: -0.4px;
        margin-bottom: 31.43px;
      }

      @include min-width(desktop) {
        max-width: 400px;
        font-size: 48px;
        line-height: 58px;
        letter-spacing: -0.4px;
        margin-bottom: 36px;
      }

      @include min-width(large-desktop) {
        max-width: 472px;
        font-size: 48px;
        line-height: 52px;
        letter-spacing: 0;
        margin-bottom: 60px;
      }
    }

    .Description {
      max-width: 254px;
      @include min-width(large-tablet) {
        max-width: 547px;
      }
    }

    .CTAButton {
      padding: 10px 28px;

      @include min-width(tablet) {
        padding: 14px 38px;
      }
    }
  }

}

.AspectRatioBox {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: (19 / 25) * 100%;
}

.AspectRatioBoxInside {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

@include min-width(small-tablet) {
  .AspectRatioBox {
    padding-top: (113 / 167) * 100%;
  }
}

@include min-width(small-desktop) {
  .AspectRatioBox {
    height: 0;
    overflow: hidden;
    position: relative;
    padding-top: 133.3%;
  }
}

@include min-width(desktop) {
  .AspectRatioBox {
    height: 0;
    overflow: hidden;
    position: relative;
    padding-top: (835 / 1620) * 100%;
  }
}
